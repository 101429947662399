/* eslint-disable indent */
/* eslint-disable array-bracket-newline */
import { useEffect, useRef, ReactNode, useState, useMemo, useCallback } from 'react';
import { Tooltip, colors, Select } from 'syngenta-digital-cropwise-react-ui-kit';
import SoilTag from 'components/SoilTag';
import { useTranslation } from 'react-i18next';
import ButtonBase, { EButtonType } from 'components/Buttons/ButtonBase';
import EnvironmentWeatherPanel from './EnvironmentalPanelContent';
import { SoilColors } from 'utils/constants/SoilColors';
import { DownOutlined } from '@ant-design/icons';
import {
  StyledDrawer,
  Tabs,
  StyledSelectedSoilsContainer,
  StyledSoilSelect,
  Collapse,
  Panel,
  StyledSoilTitle,
  StyledSoilTitle1,
  StyledDivider,
  TagContainer,
  StyledTitle,
} from './EnvironmentDrawer.styles';
import { useAppState } from 'context/AppState';
import { SoilType, SoilTypeResponse } from 'base/types/SoilType';
import { getFieldDefaultName, getTranslatedFieldName } from 'utils/constants/Fields';
import { useBreakpoint } from 'hooks';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { Field, TempSoils } from 'context/store/recommendationFormReducer';
import track from 'utils/amplitudeWrapper';
import { areArraysEqual, findArrayDifference } from 'utils/helpers/arrayOperations';
import { SoilColor, SoilTexture } from 'components/SoilTag/SoilTag.styles';
import CustomExpandIcon from 'components/CustomExpandIcon/CustomExpandIcon';
import { FlowSteps } from 'context/store/flowReducer';
import { getCountryCropRegion } from 'utils/constants/CountryData';
import { useApiDataActions } from 'context/actions/ApiDataActions';

type TabItem = {
  key: string;
  label: ReactNode;
  children: ReactNode;
};

type FieldSoil = {
  id: string;
  fieldName: string;
  selectedSoils: string[];
};

interface EnvironmentDrawerProps {
  open: boolean;
  onUpdate?: () => void;
  onClose?: () => void;
  nextClicked?: boolean;
  handleCollapseClick?: () => void;
}

interface ISoilColor {
  key: string;
  label: string;
  color: string;
}

const { Option } = Select;

const getUniqueSoils = ({ soils = [] }: { soils: SoilType[] }): string[] => {
  // remove duplicated soil types
  const uniqueSoils = soils
    .map((item) => `${item.texture} ${item.texture_raw}`)
    .filter((value, index, self) => self.indexOf(value) === index);
  return uniqueSoils;
};

export default function EnvironmentDrawer(props: EnvironmentDrawerProps): JSX.Element {
  const { open, onUpdate, onClose, nextClicked, handleCollapseClick } = props;
  const [activeField, setActiveField] = useState('1');
  const screens = useBreakpoint();
  const { isMobile, landscape } = screens;
  const {
    flow: { currentModeType, currentStep },
    recommendationForm: { fields, tempBoundaryField, fieldIdSelected, countryCode },
    apiData: { fieldSoils },
  } = useAppState();
  const { setSelectedSoilsInFields, setSoilsInFields, setTempBoundaryField } =
    useRecommendationFormActions();
  const [localFields, setLocalFields] = useState<FieldSoil[]>([]);
  const recommendedSoils = useRef<string[]>([]);
  const hasSoilTypeChangedOnce = useRef(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [showSoilAdjusted, setShowSoilAdjusted] = useState(false);
  const ApiDataActions = useApiDataActions();
  const [t] = useTranslation();
  const fieldSoilsCache = useRef<any[]>([]);
  const [isGetFieldSoils, setIsGetFieldSoils] = useState(false);

  const MAX_CHARS_IN_FIELD = 12;
  const MAX_FIELDS_IN_TABS = isMobile ? 2 : 1;

  type FieldSoilKey = keyof typeof fieldSoils;

  const RenderFieldData = (props: {
    id: string;
    soilTypeSelected: string[];
    isTempField: boolean;
  }): JSX.Element => {
    const { id, soilTypeSelected, isTempField } = props;

    const toggleSoilType = useCallback(
      (key: string) => {
        const updatedLocalFields = [...localFields].map((localField) => {
          if (localField.id === id) {
            return {
              ...localField,
              selectedSoils: [key],
            };
          }
          return localField;
        });
        setLocalFields(updatedLocalFields);
        setIsUpdateDisabled(false);
      },
      [id]
    );

    const renderSelectedSoils = useCallback(
      (selectedSoilsColor: ISoilColor[]) => {
        return selectedSoilsColor.map((selectedSoilColor) => (
          <SoilTag
            key={selectedSoilColor.key}
            color={selectedSoilColor.color}
            checked
            onChange={() => toggleSoilType(selectedSoilColor.key)}
            soilLabel={t(selectedSoilColor.label)}
            data-testid="selected-soil-tag"
            isMobile={isMobile}
          />
        ));
      },
      [toggleSoilType]
    );

    const renderSoilTag = (unselectedSoilColor: ISoilColor) => (
      <SoilTag
        key={unselectedSoilColor.key}
        color={unselectedSoilColor.color}
        onChange={() => toggleSoilType(unselectedSoilColor.key)}
        soilLabel={t(unselectedSoilColor.label)}
        isMobile={isMobile}
      />
    );

    const renderUnselectedSoils = useCallback(
      (unselectedSoilsColors: ISoilColor[]) => {
        return unselectedSoilsColors.map(renderSoilTag);
      },
      [toggleSoilType, isMobile, t]
    );
    const [selectedSoilsColor, unselectedSoilsColors] = useMemo(() => {
      const soilColorsCopy = [...SoilColors];
      const selectedSoilsColor = [];
      const unselectedSoilsColors = [];

      for (const soilColor of soilColorsCopy) {
        const formattedLabel = soilColor.label
          ? soilColor.label
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
          : '';
        if (soilTypeSelected.includes(soilColor.key)) {
          selectedSoilsColor.push({
            ...soilColor,
            label: formattedLabel,
          });
        } else {
          unselectedSoilsColors.push({
            ...soilColor,
            label: formattedLabel,
          });
        }
      }
      return [selectedSoilsColor, unselectedSoilsColors];
    }, [soilTypeSelected]);

    const handleSoilOnChange = (label: string) => {
      const selectedSoil = unselectedSoilsColors.find((item) => item.label === label);
      setShowSoilAdjusted(true);
      if (selectedSoil) {
        toggleSoilType(selectedSoil.key);
      }
    };

    const getSelectedValue = () => {
      return selectedSoilsColor.map((item) => item.label);
    };

    const toTitleCase = (str: string) => {
      const translatedLabel = t(str);
      return translatedLabel.toLowerCase().replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
    };

    return isMobile ? (
      <div data-testid="drawer-wrapper">
        <StyledSoilTitle>{t('Soil Texture')}</StyledSoilTitle>
        <StyledDivider />
        <p>{t('Soil Texture Description')}</p>
        <p>
          {t('The soil type is predicted by the system, you can assign the soil type manually.')}
        </p>
        <StyledSelectedSoilsContainer>
          {renderSelectedSoils(selectedSoilsColor)}
        </StyledSelectedSoilsContainer>
        {showSoilAdjusted && (
          <StyledSoilTitle style={{ color: '#14803C', fontSize: '12px', marginBottom: '10px' }}>
            {t('Your soil texture has been adjusted')}
          </StyledSoilTitle>
        )}
        {unselectedSoilsColors.length > 0 && (
          <>
            <StyledSoilTitle1>{t('Adjust Soil Texture')}</StyledSoilTitle1>
            <StyledSoilSelect
              suffixIcon={<DownOutlined color={colors.neutral60} />}
              value={getSelectedValue()}
              onChange={handleSoilOnChange}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {[...selectedSoilsColor, ...unselectedSoilsColors].map((soil) => (
                <Option key={`${soil.key}`} value={`${soil.label}`}>
                  <div>
                    <SoilColor
                      style={{ backgroundColor: soil.color }}
                      data-testid={`data-testid-color`}
                    >
                      <SoilTexture>{toTitleCase(soil.label)}</SoilTexture>
                    </SoilColor>
                  </div>
                </Option>
              ))}
            </StyledSoilSelect>
          </>
        )}
      </div>
    ) : (
      <div data-testid="drawer-wrapper">
        <Collapse defaultActiveKey={['panel-1', 'panel-2']} expandIcon={CustomExpandIcon}>
          <Panel
            header={t('Soil Type')}
            key="panel-1"
            showContent={(isMobile && !landscape) || !isMobile}
            showHeader={(isMobile && !landscape) || !isMobile}
          >
            <p>{t('Soil Type Description')}</p>
            <StyledSelectedSoilsContainer>
              {renderSelectedSoils(selectedSoilsColor)}
            </StyledSelectedSoilsContainer>
            <StyledDivider />
            <TagContainer>{renderUnselectedSoils(unselectedSoilsColors)}</TagContainer>
          </Panel>
          <Panel
            header={t('Environment Chart')}
            key="panel-2"
            showContent={true}
            showHeader={(isMobile && !landscape) || !isMobile}
          >
            <EnvironmentWeatherPanel id={id} isTempField={isTempField} />
          </Panel>
        </Collapse>
      </div>
    );
  };
  const assignFirstTimeSoilsInStore = (soils: SoilTypeResponse, fields: Field[]) => {
    // This soils only applies for the fetch data
    fields.forEach((field) => {
      if (!field.soils?.length) {
        setSoilsInFields({ fieldId: field.id, soils: soils.soilGrid[field.id as FieldSoilKey] });
      }
    });

    fields.forEach(({ id, selectedSoils }) => {
      if (!selectedSoils?.length) {
        setSelectedSoilsInFields({
          fieldsResolution: fieldSoils.fieldsResolution,
          selectedSoils: getUniqueSoils({ soils: fieldSoils.soilGrid[id as FieldSoilKey] }),
          fieldId: id,
        });
      }
    });
  };

  function trackSoilTypeChanges(updatedSoils: string[]): void {
    const hasUserChangedSoilType = !areArraysEqual(recommendedSoils.current, updatedSoils);
    const newSoilTypes = findArrayDifference(recommendedSoils.current, updatedSoils);
    if (newSoilTypes.length > 0) {
      track('soil updated info', { 'soil updated': newSoilTypes.join(',') });
    }
    if (!hasSoilTypeChangedOnce.current && hasUserChangedSoilType) {
      hasSoilTypeChangedOnce.current = true;
      track('change soil type', {
        'soil type changed': true,
      });
    }
  }

  useEffect(() => {
    const soilFieldsInfo = fields.map((fieldItem) => {
      return {
        id: `${fieldItem.id}`,
        fieldName: fieldItem.fieldName,
        selectedSoils:
          fieldItem.selectedSoils && fieldItem.selectedSoils.length > 0
            ? fieldItem.selectedSoils
            : getUniqueSoils({ soils: fieldSoils.soilGrid[fieldItem.id as FieldSoilKey] }),
      };
    });
    if (tempBoundaryField?.properties?.id) {
      const fieldIds = fields.map((item) => item.id);
      if (!fieldIds.includes(tempBoundaryField.properties.id)) {
        // Soils for the temp boundary field
        const tempSoils: TempSoils = {
          fieldsResolution: fieldSoils.fieldsResolution,
          soils: fieldSoils.soilGrid[tempBoundaryField.properties.id as FieldSoilKey],
          selectedSoils:
            tempBoundaryField.selectedSoils && tempBoundaryField.selectedSoils.length > 0
              ? tempBoundaryField.selectedSoils
              : getUniqueSoils({
                  soils: fieldSoils.soilGrid[tempBoundaryField.properties?.id as FieldSoilKey],
                }),
        };
        soilFieldsInfo.push({
          id: `${tempBoundaryField.properties?.id}`,
          fieldName: getFieldDefaultName({ fields }),
          selectedSoils:
            tempBoundaryField.selectedSoils && tempBoundaryField.selectedSoils.length > 0
              ? tempBoundaryField.selectedSoils
              : getUniqueSoils({
                  soils: fieldSoils.soilGrid[tempBoundaryField.properties?.id as FieldSoilKey],
                }),
        });
        setTempBoundaryField({ boundary: { ...tempBoundaryField, ...tempSoils } });
      }
    }

    assignFirstTimeSoilsInStore(fieldSoils, fields);

    recommendedSoils.current = soilFieldsInfo.map((field) => field.selectedSoils).flat();

    setLocalFields(soilFieldsInfo as FieldSoil[]);
    if (fieldIdSelected !== '') {
      setActiveField(`${fieldIdSelected}`);
    }
    setIsUpdateDisabled(true);
    // eslint-disable-next-line
  }, [fieldSoils, fieldIdSelected, currentModeType, t]);

  const items: TabItem[] = localFields.map((fieldItem) => {
    const fieldName = fieldItem.fieldName;
    let labelItem: ReactNode = getTranslatedFieldName({ defaultName: fieldName, t });
    if (fieldItem.fieldName.length > MAX_CHARS_IN_FIELD) {
      labelItem = (
        <Tooltip placement="top" title={fieldItem.fieldName}>
          {`${fieldItem.fieldName.slice(0, MAX_CHARS_IN_FIELD)}...`}
        </Tooltip>
      );
    }

    return {
      key: `${fieldItem.id}`,
      label: labelItem,
      children: (
        <>
          <RenderFieldData
            id={`${fieldItem.id}`}
            soilTypeSelected={fieldItem.selectedSoils}
            isTempField={!fields.find(({ id }) => fieldItem.id === id.toString())}
          />
          {isMobile && (
            <>
              <StyledSoilTitle>{t('Environment Chart')}</StyledSoilTitle>
              <StyledDivider />
              <EnvironmentWeatherPanel
                id={`${fieldItem.id}`}
                isTempField={!fields.find(({ id }) => fieldItem.id === id.toString())}
              />
            </>
          )}
        </>
      ),
    };
  });
  const onCancel = () => {
    onClose?.();
  };

  const handleUpdate = () => {
    // Case for tempBoundaryField
    if (tempBoundaryField?.properties?.id) {
      const tempLocalField = localFields?.find(
        (field) => `${field.id}` === `${tempBoundaryField.properties?.id}`
      );
      if (tempLocalField) {
        const tempSoils: TempSoils = {
          soils: tempBoundaryField.soils,
          selectedSoils: tempLocalField?.selectedSoils,
          fieldsResolution: fieldSoils.fieldsResolution,
        };
        setTempBoundaryField({ boundary: { ...tempBoundaryField, ...tempSoils } });
      }
    }

    const updatedSoils: string[] = [];
    // All remaining fields
    localFields.forEach(({ id, selectedSoils }) => {
      updatedSoils.push(...selectedSoils);
      setSelectedSoilsInFields({
        fieldsResolution: fieldSoils.fieldsResolution,
        selectedSoils: selectedSoils,
        fieldId: id,
      });
    });

    trackSoilTypeChanges(updatedSoils);
    onUpdate?.();
  };

  useEffect(() => {
    handleUpdate();
  }, [nextClicked]);

  const footer = (
    <>
      <ButtonBase
        textSize="MEDIUM"
        onClick={onCancel}
        text={t('Cancel')}
        style={{ minWidth: isMobile ? '170px' : '76px', height: '40px' }}
        buttonTestId="environment-info-cancel-button"
      />
      <ButtonBase
        textSize="MEDIUM"
        type={EButtonType.primary}
        onClick={handleUpdate}
        style={{ minWidth: isMobile ? '170px' : '76px', height: '40px' }}
        text={t('Update')}
        isDisabled={isUpdateDisabled}
        buttonTestId="environment-info-update-button"
      />
    </>
  );
  const onChange = (activeKey: string) => {
    setActiveField(activeKey);
  };

  const NoTabsBar = () => {
    return <div />;
  };

  const getFieldsSoils = async () => {
    const drawnFields = fields.map((item) => ({
      id: `${item.id}`,
      geometry: {
        type: item.boundary.geometry.type,
        coordinates: [...item.boundary.geometry.coordinates],
      },
    }));
    if (tempBoundaryField?.properties?.id) {
      const fieldsIds = fields.map((item) => item.id);
      if (!fieldsIds.includes(tempBoundaryField.properties.id)) {
        drawnFields.push({
          id: `${tempBoundaryField.properties?.id}`,
          geometry: {
            type: tempBoundaryField.geometry.type,
            coordinates: tempBoundaryField.geometry.coordinates,
          },
        });
      }
    }

    if (!isGetFieldSoils && drawnFields.length > 0) {
      const cacheGeometry = drawnFields;
      await ApiDataActions.getFieldSoils(
        drawnFields,
        getCountryCropRegion(countryCode),
        'SYNGENTA',
        JSON.stringify(fieldSoilsCache.current) === JSON.stringify(cacheGeometry)
      );
      fieldSoilsCache.current = cacheGeometry;
      setIsGetFieldSoils(true);
    }
  };

  const handleOnCollapseClick = () => {
    getFieldsSoils();
    if (handleCollapseClick) {
      handleCollapseClick();
    }
  };

  const renderTabBar = localFields.length < MAX_FIELDS_IN_TABS ? NoTabsBar : undefined;
  return (
    <>
      {isMobile && currentStep === FlowSteps.STEP1 ? (
        <Collapse
          bordered={false}
          ghost
          expandIcon={CustomExpandIcon}
          onChange={handleOnCollapseClick}
        >
          <Panel
            header={t('View environment information')}
            key="panel-1"
            showContent={isMobile && !landscape}
            showHeader={isMobile && !landscape}
            style={{ marginBottom: '30px' }}
          >
            <Tabs
              activeKey={activeField}
              items={items}
              onChange={onChange}
              renderTabBar={renderTabBar}
            />
          </Panel>
        </Collapse>
      ) : (
        <StyledDrawer
          isMobile={isMobile}
          open={open}
          placement={isMobile ? 'bottom' : 'right'}
          title={!isMobile ? t('Environment Information') : ''}
          width={684}
          onClose={onCancel}
          getContainer={false}
          footer={footer}
          closable={!isMobile}
        >
          {open && (
            <>
              {isMobile && <StyledTitle>{t('Environment Information')}</StyledTitle>}
              <p>{t('Drawer Environment Information description')}</p>
              <Tabs
                activeKey={activeField}
                items={items}
                onChange={onChange}
                renderTabBar={renderTabBar}
              />
            </>
          )}
        </StyledDrawer>
      )}
    </>
  );
}
