/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppState, useAppDispatch } from 'context/AppState';
import { useBreakpoint } from 'hooks';
import { useFlowActions } from 'context/actions/flowActions';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Feature, Polygon, Position, centroid } from '@turf/turf';

import { MapTools, ItemToolProps } from 'components/MapTools';
import IconUndo from 'components/Icons/IconUndo';
import IconDelete from 'components/Icons/IconDelete';

import { colors } from 'theme/colors';
import {
  FlowSteps,
  ModeTypes,
  OptionType,
  OverlappingGeometryTypes,
} from 'context/store/flowReducer';

import { pointInCountry, pointIsInSpecificCountry } from 'utils/helpers/geospatial';
import useValidation from './hooks/useValidation';
import { MenuControl } from 'components/Maps/Controls/MenuControl';
import DropAPinControl from 'components/Maps/Controls/DropAPinContorl/DropAPinControl';
import DrawBoundaryControl from 'components/Maps/Controls/DrawBoundaryControl/DrawBoundaryControl';
import delay from 'utils/delay';
import { useMap } from 'react-map-gl';
import { CustomControl } from 'components/Maps/Controls/CustomControl';
import { notification } from 'antd';
import { AddBoundaryControl } from 'components/Maps/Controls/AddBoundaryControl';
import DetectFieldNextBtnControl from 'components/Maps/Controls/DetectFieldNextBtnControl/DetectFieldNextBtnControl';
import { ActionTypes, useApiDataActions } from 'context/actions/ApiDataActions';
import { CropConstants } from 'utils/constants/Crop';
import { isEnoughZoomForDrawBoundaryAndDropPin } from 'utils/isEnoughZoomLevelToStartWith';

const MapToolsWrapper = (): JSX.Element => {
  const [t] = useTranslation();
  const appDispatcher = useAppDispatch();
  const { isMobile, landscape } = useBreakpoint();
  const {
    flow: {
      currentModeType,
      undoDraw,
      currentStep,
      disabledButtonHub,
      showDeleteBoundaryModal,
      showCancelBoundaryModal,
      showDrawingWarning,
      showPinWarning,
      showEditBoundaryModal,
      showEnvironmentInformationModal,
      showGoBackHomeModal,
      resetBoundaryValues,
      showEnvironmentDrawer,
      showLandIsNotArableModal,
      previousModeType,
      isContinueButtonActive,
      optionType,
      isDropAPinFlow,
      showFieldInformationSheet,
      hasDetectFieldFlowStarted,
      selectedFieldMapData,
      detectFieldSelectedIds,
      tempDetectFieldSelected,
      showInvalidBoundaryModal,
    },
    recommendationForm: { tempBoundaryField, isAtleastAVertex, fieldSelected, fields },
    apiData: { formattedDetectFieldsData },
  } = useAppState();
  const ApiDataActions = useApiDataActions();
  const { current } = useMap();
  const {
    setUndoDraw,
    setCurrentModeType,
    setPreviousModeType,
    setShowDeleteBoundaryModal,
    setResetBoundaryValues,
    setAreBoundariesConfirmed,
    setMobileActiveTab,
    setListTabIsDisabled,
    setDisabledButtonHub,
    setIsDrawingBoundaries,
    setAddAnother,
    setContinueButtonActive,
    setShowEnvironmentInformationModal,
    setOverlappingGeometryType,
    setIsDropAPinFlow,
    setOptionType,
    setShowFieldInformationSheet,
    setHasDetectFieldFlowStarted,
    setTempDetectFieldSelected,
    setShowDrawingWarning,
  } = useFlowActions();
  const flags = useFlags();
  const {
    setFieldIdSelected,
    setIsDeletingTempField,
    saveFields,
    setCountryCode,
    setFieldToBeOpenedId,
  } = useRecommendationFormActions();
  const [selectedOption, setSelectedOption] = useState(0);
  const [showMapTools, setShowMapTools] = useState(false);
  const [showButtonHub, setShowButtonHub] = useState(true);
  const [showAddAnotherControl, setShowAddAnotherControl] = useState(false);
  const [isFieldSelected, setIsFieldSelected] = useState(false);
  const [forceToolTipAddBoundary, setForceToolTipAddBoundary] = useState<boolean>(false);
  const [isAnyModalOpen, setIsAnyModalOpen] = useState<boolean>(false);
  const [hasUserZoomEnoughOnce, setHasUserZoomEnoughOnce] = useState<boolean>(false);
  const isDrawBoundaryDropAPinEnabledBasedOnZoomLevel = current
    ? isEnoughZoomForDrawBoundaryAndDropPin(current)
    : false;
  useEffect(() => {
    const timeOuts: NodeJS.Timeout[] = [];

    if (!hasUserZoomEnoughOnce && isDrawBoundaryDropAPinEnabledBasedOnZoomLevel) {
      const delay = formattedDetectFieldsData?.features?.length > 0 ? 3000 : 8000;
      const timeOut = setTimeout(() => {
        if (
          (formattedDetectFieldsData?.features?.length > 0 &&
            selectedFieldMapData?.features?.length === 0) ||
          formattedDetectFieldsData?.features?.length === 0
        ) {
          setHasUserZoomEnoughOnce(true);
        }
      }, delay);
      timeOuts.push(timeOut);
    }

    return () => {
      timeOuts.forEach(clearTimeout);
    };
  }, [
    isDrawBoundaryDropAPinEnabledBasedOnZoomLevel,
    formattedDetectFieldsData,
    selectedFieldMapData,
  ]);
  const showDropOption =
    (optionType === OptionType.empty || optionType === OptionType.Detect) &&
    detectFieldSelectedIds.length === 0 && hasUserZoomEnoughOnce &&
    fields.length === 0 && !isAnyModalOpen;
  const showDrawOption = useMemo(() => {
    return (
      (optionType === OptionType.empty || optionType === OptionType.Detect) &&
      (detectFieldSelectedIds.length === 0 || fields.length > 0) &&
      hasUserZoomEnoughOnce &&
      !isAnyModalOpen
    );
  }, [optionType, fields, detectFieldSelectedIds, isAnyModalOpen, hasUserZoomEnoughOnce]);

  const isDrawBoundaryBtnDisabled =
    optionType === OptionType.Detect && tempDetectFieldSelected.length > 0;
  const showDetectFieldNextBtn = useMemo(() => {
    return (
      !isAnyModalOpen &&
      optionType === OptionType.Detect &&
      (detectFieldSelectedIds.length > 0 || fields.length > 0)
    );
  }, [detectFieldSelectedIds, optionType, isAnyModalOpen, fields]);
  const { collapsible, disableUndo, disableConfirm, disableDelete, disableDraw } = useValidation({
    currentModeType,
    areTempBoundary: Boolean(tempBoundaryField),
    isAtleastAVertex,
    isFieldSelected,
    isMobile,
  });
  const menuControl = new CustomControl('menuControl');
  const addBoundaryControl = new CustomControl('addBoundaryControl');
  const drawBoundaryControl = new CustomControl('drawBoundaryControl');
  const dropAPinControl = new CustomControl('dropAPinControl');
  const detectFieldNextBtnControl = new CustomControl('detectFieldNextBtnControl');
  useEffect(() => {
    setShowMapTools(isAtleastAVertex);
  }, [isAtleastAVertex]);

  useEffect(() => {
    if (isMobile) {
      if (!current?.getMap().hasControl(addBoundaryControl)) {
        current?.getMap().addControl(addBoundaryControl, 'bottom-right');
      }
      if (!current?.getMap().hasControl(menuControl)) {
        current?.getMap().addControl(menuControl, 'bottom-right');
      }
      if (!current?.getMap().hasControl(detectFieldNextBtnControl)) {
        current?.getMap().addControl(detectFieldNextBtnControl, 'bottom-right');
      }
      if (!current?.getMap().hasControl(dropAPinControl)) {
        current?.getMap().addControl(dropAPinControl, 'bottom-right');
      }
      if (!current?.getMap().hasControl(drawBoundaryControl)) {
        current?.getMap().addControl(drawBoundaryControl, 'bottom-right');
      }
    } else {
      current?.getMap().removeControl(menuControl);
      current?.getMap().removeControl(addBoundaryControl);
      current?.getMap().removeControl(drawBoundaryControl);
      current?.getMap().removeControl(dropAPinControl);
      current?.getMap().removeControl(detectFieldNextBtnControl);
    }
  }, [isMobile]);

  useEffect(() => {
    if (isMobile || landscape) {
      setIsAnyModalOpen(
        !!(
          showDeleteBoundaryModal ||
          showCancelBoundaryModal ||
          showDrawingWarning ||
          showPinWarning ||
          showEditBoundaryModal ||
          showEnvironmentInformationModal ||
          showEnvironmentDrawer ||
          showGoBackHomeModal ||
          showFieldInformationSheet ||
          showInvalidBoundaryModal
        )
      );
    }
  }, [
    isMobile,
    landscape,
    showDeleteBoundaryModal,
    showCancelBoundaryModal,
    showDrawingWarning,
    showEditBoundaryModal,
    showEnvironmentInformationModal,
    showGoBackHomeModal,
    showEnvironmentDrawer,
    showFieldInformationSheet,
    showInvalidBoundaryModal,
  ]);
  useEffect(() => {
    if (!isAnyModalOpen) {
      delay(220).then(() => setForceToolTipAddBoundary(true));
    } else {
      setForceToolTipAddBoundary(false);
      delay(220).then(() => setForceToolTipAddBoundary(false));
    }
  }, [showButtonHub, isAnyModalOpen]);

  useEffect(() => {
    if (fieldSelected) {
      setIsFieldSelected(true);
    } else {
      setIsFieldSelected(false);
    }
  }, [fieldSelected]);

  useEffect(() => {
    showMapTools && setShowMapTools(!collapsible);
  }, [collapsible]);

  useEffect(() => {
    if (currentModeType === ModeTypes.CREATING || currentModeType === ModeTypes.EDITING) {
      setSelectedOption(0);
    }
  }, [currentModeType]);

  useEffect(() => {
    if (
      currentStep === FlowSteps.STEP1 &&
      optionType === OptionType.Draw &&
      (currentModeType === ModeTypes.SAVING || currentModeType === ModeTypes.NO_ACTION)
    ) {
      setShowAddAnotherControl(true);
    } else {
      setShowAddAnotherControl(false);
    }
  }, [currentModeType, currentStep]);

  useEffect(() => {
    if (
      previousModeType === ModeTypes.DELETING &&
      showLandIsNotArableModal === false &&
      showMapTools &&
      (currentModeType === ModeTypes.CREATING || currentModeType === ModeTypes.NO_ACTION)
    ) {
      setShowMapTools(false);
    }
  }, [showLandIsNotArableModal]);

  const handleDeleteClick = useCallback(() => {
    setIsDrawingBoundaries({ isDrawing: false });
    setPreviousModeType({ modeType: currentModeType });
    setShowDeleteBoundaryModal({ show: true });
    setSelectedOption(0);
    setOverlappingGeometryType({ type: OverlappingGeometryTypes.NONE });
    if (isMobile) {
      setIsDeletingTempField({ isDeletingTempField: true });
    }
  }, [currentModeType, resetBoundaryValues]);

  const handleUndoClick = useCallback(() => {
    setUndoDraw({ undoDraw: !undoDraw });
    setSelectedOption(0);
    if (isMobile && isContinueButtonActive) {
      setContinueButtonActive({ active: false });
      notification.close('environmentInfoMobileNotification');
      setShowEnvironmentInformationModal({ show: false });
    }
  }, [undoDraw, isContinueButtonActive]);

  const focusFieldNameInput = useCallback(() => {
    window.setTimeout(() => {
      const input = document.getElementById('input-text-handler-fieldName');
      input?.focus();
    }, 500);
  }, []);

  useEffect(() => {
    if (isMobile && tempBoundaryField && currentModeType !== ModeTypes.EDITING) {
      focusFieldNameInput();
      setAreBoundariesConfirmed({ areBoundariesConfirmed: true });
      setShowButtonHub(false);
      setFieldIdSelected({ fieldId: `${tempBoundaryField?.properties?.id}` });
      setDisabledButtonHub({ disabled: true });
    }
  }, [tempBoundaryField]);

  const handleOnMapToolsClick = useCallback(() => {
    setShowMapTools(!showMapTools);
  }, [showMapTools]);

  const mobileMenuControlOptions = [
    {
      label: 'Delete',
      icon: (
        <IconDelete
          width={14}
          height={16}
          color={disableDelete ? colors.neutral20 : colors.red50}
        />
      ),
      onClick: handleDeleteClick,
      isOnlyPressable: true,
      buttonProps: {
        disabled: disableDelete,
      },
    },
    {
      icon: (
        <IconUndo
          width={18}
          height={18}
          color={disableUndo ? colors.neutral20 : colors.neutral70}
        />
      ),
      label: 'Undo',
      isOnlyPressable: true,
      buttonProps: {
        disabled: disableUndo,
      },
      onClick: handleUndoClick,
    },
  ];
  const menuControlOptions: ItemToolProps[] = useMemo(
    () => [
      {
        label: t('Delete'),
        tooltipLabel: '',
        tooltipProps: { open: false },
        icon: <IconDelete width={14} height={16} />,
        onClick: handleDeleteClick,
        isOnlyPressable: true,
        disabled: disableDelete,
      },
      {
        tooltipProps: { open: false },
        icon: <IconUndo width={18} height={18} color={colors.red50} />,
        label: t('Undo'),
        tooltipLabel: '',
        isOnlyPressable: true,
        disabled: disableUndo,
        onClick: handleUndoClick,
      },
    ],
    [
      collapsible,
      disableUndo,
      disableConfirm,
      disableDelete,
      disableDraw,
      handleUndoClick,
      handleDeleteClick,
      t,
    ]
  );
  const addBoundaryTooltipProps = {
    open: forceToolTipAddBoundary,
  };

  useEffect(() => {
    switch (currentModeType) {
      case ModeTypes.CANCELLING:
      case ModeTypes.SAVING:
      case ModeTypes.NO_ACTION:
      case ModeTypes.DELETING:
      case ModeTypes.CONFIRMING_AFTER_EDIT:
        setShowButtonHub(false);
        break;
      case ModeTypes.CREATING:
      case ModeTypes.EDITING:
        setShowButtonHub(true);
        break;
      default:
        break;
    }
  }, [currentModeType]);

  const handleToggleMenuControl = () => {
    if (
      currentModeType !== ModeTypes.NO_ACTION &&
      currentModeType !== ModeTypes.DELETING &&
      currentModeType !== ModeTypes.SAVING
    ) {
      setShowButtonHub(!showButtonHub);
    }
  };

  const handleAddNewBoundary = () => {
    setCurrentModeType({ modeType: ModeTypes.CREATING });
    setAddAnother({ addAnother: true });
    setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
    setAreBoundariesConfirmed({ areBoundariesConfirmed: false });
    setMobileActiveTab({ activeTab: 'Map' });
    setShowButtonHub(true);
    setListTabIsDisabled({ isDisabled: true });
    setContinueButtonActive({ active: false });
    setIsDrawingBoundaries({ isDrawing: true });
  };

  const handleDrawBoundary = () => {
    if (fields.length > 0 && optionType !== OptionType.Detect) {
      saveFields({ fields: [] });
    }
    appDispatcher({
      type: ActionTypes.setDetectField, payload: {
        formattedDetectField: {
          type: 'FeatureCollection',
          features: [],
        }
      }
    });
    setIsDropAPinFlow({ isDropAPin: false });
    setCurrentModeType({ modeType: ModeTypes.CREATING });
    setOptionType({ type: OptionType.Draw });
    setIsDrawingBoundaries({ isDrawing: true });
  };

  const handleDropAPin = () => {
    setIsDropAPinFlow({ isDropAPin: true });
    setOptionType({ type: OptionType.Drop });
    setIsDrawingBoundaries({ isDrawing: false });
    fields.length > 0 && saveFields({ fields: [] });
  };

  const handleDetectFieldNext = async () => {
    setTempDetectFieldSelected({ selected: [] });
    if (selectedFieldMapData.features.length > 0) {
      const selectedDetectField = selectedFieldMapData.features.map((field, i) => {
        const center = centroid(field.geometry);
        const boundary: Feature<Polygon> = {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: field.geometry.coordinates as unknown as Position[][],
          },
          properties: {
            id: field.id,
            center: center.geometry.coordinates,
          },
        };
        return {
          id: field.id,
          fieldName: `Field ${String(i + 1).padStart(2, '0')}`,
          hectares: parseFloat(field.properties.hectares).toFixed(2),
          isDetectedField: true,
          detectFielduniqueId: field.properties.fieldUniqueId,
          isSaved: true,
          soils: undefined,
          weather: undefined,
          selectedSoils: [],
          fieldsResolution: 0,
          boundary,
        };
      });
      const [lng, lat] =
        selectedDetectField[selectedDetectField.length - 1].boundary.geometry.coordinates[0][0];
      if (pointIsInSpecificCountry({ lng, lat }, CropConstants.SOUTH_AFRICA_CODE)) {
        try {
          const tppData = await ApiDataActions.getTPPForFields(
            CropConstants.SOUTH_AFRICA,
            CropConstants.WHEAT_SMALLCASE,
            [lat, lng]
          );
          if (!tppData?.spatialClass) {
            setShowDrawingWarning({ show: true });
            return;
          }
        } catch (e) {
          console.error(e);
        }
      }
      setFieldIdSelected({ fieldId: `${selectedDetectField[selectedDetectField.length - 1].id}` });
      saveFields({ fields: selectedDetectField });
      setFieldToBeOpenedId({
        fieldId: `${selectedDetectField[selectedDetectField.length - 1].id}`,
      });
      const countryCode = pointInCountry({ lng, lat }, flags);
      console.log('countryCode MapToolsWrapper', countryCode);
      setCountryCode({ countryCode: countryCode ?? '' });
    }
    const tooltipElememnts = document.getElementsByClassName(
      'syt-antd-tooltip'
    ) as HTMLCollectionOf<HTMLElement>;
    if (tooltipElememnts.length) {
      for (let i = 0; i < tooltipElememnts.length; i++) {
        tooltipElememnts[i].style.display = 'none';
      }
    }
    setShowFieldInformationSheet({ showFieldInformationSheet: true });
    !hasDetectFieldFlowStarted && setHasDetectFieldFlowStarted({ hasDetectFieldFlowStarted: true });
  };

  return (
    <>
      <div
        className="tool-container"
        data-testid="mapToolsContainer"
        style={{ display: !isMobile && currentStep === FlowSteps.STEP1 ? 'block' : 'none' }}
      >
        <MapTools
          title={t('Map tools')}
          data-testid="MapTools"
          items={menuControlOptions}
          id="mapTools"
          selectedTool={selectedOption}
          collapsible={collapsible ? 'disabled' : undefined}
          activeKey={showMapTools ? ['1'] : ['0']}
          handleSelectedToolChange={setSelectedOption}
          onChange={handleOnMapToolsClick}
        />
      </div>
      <div style={{ display: isMobile && currentStep === FlowSteps.STEP1 ? 'block' : 'none' }}>
        <MenuControl
          id="menuControl"
          openIconType={'POLIGON'}
          isOpen={true}
          isVisible={!showDropOption && !isDropAPinFlow}
          onToggleMenuControl={handleToggleMenuControl}
          options={mobileMenuControlOptions}
          buttonRootProps={{ disabled: disabledButtonHub && !tempBoundaryField }}
        />
        <AddBoundaryControl
          id="addBoundaryControl"
          isVisible={showAddAnotherControl}
          onClick={handleAddNewBoundary}
          tooltipProps={addBoundaryTooltipProps}
        />
        <DrawBoundaryControl
          id="drawBoundaryControl"
          isVisible={showDrawOption}
          onClick={handleDrawBoundary}
          disabled={isDrawBoundaryBtnDisabled || !isDrawBoundaryDropAPinEnabledBasedOnZoomLevel}
        />
        <DropAPinControl
          id="dropAPinControl"
          isVisible={showDropOption}
          onClick={handleDropAPin}
          disabled={!isDrawBoundaryDropAPinEnabledBasedOnZoomLevel}
        />
        <DetectFieldNextBtnControl
          id="detectFieldNextBtnControl"
          data-testid="detectFieldNextBtn"
          isVisible={showDetectFieldNextBtn}
          onClick={handleDetectFieldNext}
        />
      </div>
    </>
  );
};

export default MapToolsWrapper;
