/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import track from 'utils/amplitudeWrapper';
import {
  IconChevronRightStyled,
  ColumnTitle,
  TableStyled,
  TrStyled,
  CommonText,
  RankText,
  VarietyText,
  TrExpandableRow,
  TdMainRow,
  DivFilterStyled,
  IconSortStyled,
  TdColumnTitle,
  TrTitleRow,
  SkuText,
} from './styles';
import { SeedDensityField } from '../SeedDensityAdvice';
import { RankedSeed } from 'base/types/RecommendationCropwise';
import { numberToEuropeanFormat } from 'utils/numberFormat';
import FieldCeil from '../FieldCeilComponent';
import { useAppState } from 'context/AppState';
import { CustomTooltip } from 'components/CustomTooltip';
import { CropConstants } from 'utils/constants/Crop';
import { convertSeedsToBagsForProduct } from 'utils/convertSeedsToBagsForProduct';

type TSeedDemandKey = 'rank' | 'variety' | 'seeds' | 'plantingArea' | 'numberOfBags' | 'fieldName';

type TColumn = {
  title: string;
  dataIndex: string;
  key: string;
};

type SkuDataItem = {
  commercial_name: string;
  sku_description: string;
};

type TSeedDemand = {
  rank: number | string;
  variety: string;
  seeds: string | number;
  plantingArea: string | number;
  numberOfBags: string | number;
};

export type TData = {
  fieldName: string;
  fieldCoordinates: number[][][];
  seedDemand: TSeedDemand[];
  area: string | number;
  expanded?: boolean;
};

interface IProps {
  dataSource: SeedDensityField[];
  columns: TColumn[];
  countryCode?: string;
  isNonDDG: boolean;
  recommendationAssignments?: any;
  recommendationCropwise?: any;
}

interface ExpandableRowProps {
  data: RankedSeed;
  index: number;
  updatedColumns: { key: string }[];
  getSkuDescription: (varietyName: string) => string;
  nonDDGDataOverride: any;
  isNonDDG: boolean;
  field?: string;
  productCatalog?: any;
  configRecommendation?: any;
}

const ExpandableRow: React.FC<ExpandableRowProps> = ({
  data,
  index,
  updatedColumns,
  getSkuDescription,
  nonDDGDataOverride,
  productCatalog,
  isNonDDG,
  field,
  configRecommendation,
}) => {
  const getExpandableRowValue = (key: string, data: RankedSeed) => {
    if (key === 'sku') {
      return getSkuDescription(data.variety);
    } else if (typeof data[key as keyof RankedSeed] === 'number') {
      return numberToEuropeanFormat(data[key as keyof RankedSeed] as number) || '';
    } else {
      return (data[key as keyof RankedSeed] as number) || '';
    }
  };

  const getExpandableRowValueForNonDDG = (key: string, data: RankedSeed) => {
    const seedName = data.Seed?.SeedName;

    const getSeedRate = () => {
      if (nonDDGDataOverride?.seed_rate) {
        return nonDDGDataOverride?.seed_rate;
      } else if (data && data.Seed) {
        const calculatedSeedRates = configRecommendation
          .find((item: any) => item.FieldId === field)
          ?.CalculatedSeedRate.reduce((acc: any, item: any) => {
            return { ...acc, ...item };
          }, {});
        return calculatedSeedRates[data.Seed.SeedName];
      }
      return 0;
    };

    const plantingArea = nonDDGDataOverride?.applied_area
      ? nonDDGDataOverride?.applied_area
      : (data[key as keyof RankedSeed] as number);
    if (key === 'sku') {
      return getSkuDescription(data.variety);
    } else if (key === 'numberOfBags') {
      const bags = Math.ceil(nonDDGDataOverride?.total_quantity_required);
      if (!bags && data.Seed) {
        const product = productCatalog.find(
          (item: any) => item.productName === seedName || item.commercialName === seedName
        );
        return Math.ceil(convertSeedsToBagsForProduct(getSeedRate(), plantingArea, product));
      }
    } else if (key === 'seeds') {
      return numberToEuropeanFormat(getSeedRate());
    } else if (key === 'plantingArea') {
      return plantingArea.toFixed(2);
    } else if (key === 'plants') {
      return data?.plants;
    } else if (typeof data[key as keyof RankedSeed] === 'number') {
      return numberToEuropeanFormat(data[key as keyof RankedSeed] as number) || '';
    } else {
      return (data[key as keyof RankedSeed] as number) || '';
    }
  };

  return (
    <TrExpandableRow displayBorder={index === 0}>
      {updatedColumns.map(({ key }) => (
        <TdMainRow key={key} centerText={key === 'plantingArea'}>
          <CommonText>
            {isNonDDG
              ? getExpandableRowValueForNonDDG(key, data)
              : getExpandableRowValue(key, data)}
          </CommonText>
        </TdMainRow>
      ))}
      <td />
    </TrExpandableRow>
  );
};

interface MainRowProps {
  data: SeedDensityField;
  updatedColumns: { title?: string; dataIndex?: string; key: string }[];
  handleExpandableRowClick: (rowData: SeedDensityField) => void;
  getSkuDescription: (varietyName: string) => string;
  nonDDGDataOverride?: any;
  isNonDDG: boolean;
}

const MainRow: React.FC<MainRowProps> = ({
  data,
  updatedColumns,
  handleExpandableRowClick,
  getSkuDescription,
  nonDDGDataOverride,
  isNonDDG,
}) => {
  const [seedDemand] = data.seedDemand;
  const mainRowColumns = [...updatedColumns];
  mainRowColumns.shift();

  const getColumnValue = (
    column: { title?: string; dataIndex?: string; key: string },
    seedDemand: RankedSeed
  ) => {
    if (column.key === 'sku') {
      return getSkuDescription(seedDemand.variety) ?? 'N/A';
    } else if (typeof seedDemand[column.key as unknown as keyof RankedSeed] === 'number') {
      return numberToEuropeanFormat(
        seedDemand[column.key as unknown as keyof RankedSeed] as number
      );
    } else {
      return seedDemand[column.key as unknown as keyof RankedSeed]?.toString() ?? '';
    }
  };

  const getColumnValueNonDDG = (
    column: { title?: string; dataIndex?: string; key: string },
    seedDemand: RankedSeed
  ) => {
    if (column.key === 'sku') {
      return getSkuDescription(seedDemand.variety) ?? 'N/A';
    } else if (column.key === 'numberOfBags') {
      return Math.ceil(nonDDGDataOverride?.total_quantity_required);
    } else if (column.key === 'seeds') {
      return numberToEuropeanFormat(nonDDGDataOverride?.seed_rate);
    } else if (column.key === 'plantingArea') {
      return nonDDGDataOverride?.applied_area.toFixed(2);
    } else if (column.key === 'plants') {
      return seedDemand?.plants;
    } else if (column.key === 'variety') {
      if (nonDDGDataOverride?.override) {
        return nonDDGDataOverride?.override?.commercialName;
      } else {
        return seedDemand[column.key as unknown as keyof RankedSeed]?.toString() ?? '';
      }
    } else if (typeof seedDemand[column.key as unknown as keyof RankedSeed] === 'number') {
      return numberToEuropeanFormat(
        seedDemand[column.key as unknown as keyof RankedSeed] as number
      );
    } else {
      return seedDemand[column.key as unknown as keyof RankedSeed]?.toString() ?? '';
    }
  };

  return (
    <TrStyled>
      <td>
        <FieldCeil {...data} />
      </td>
      {mainRowColumns.map((column) => {
        let TextStyled = CommonText;

        if (column.key === 'rank') {
          TextStyled = RankText;
        } else if (column.key === 'variety') {
          TextStyled = VarietyText;
        } else if (column.key === 'sku') {
          TextStyled = SkuText;
        }

        return (
          <TdMainRow key={column.key} centerText={column.key === 'plantingArea'}>
            {column.key === 'sku' ? (
              <CustomTooltip
                placement="left"
                title={
                  isNonDDG
                    ? (getColumnValueNonDDG(column, seedDemand) as string)
                    : getColumnValue(column, seedDemand)
                }
                trigger={'hover'}
                zIndex={1000}
              >
                <TextStyled>
                  {isNonDDG
                    ? getColumnValueNonDDG(column, seedDemand)
                    : getColumnValue(column, seedDemand)}
                </TextStyled>
              </CustomTooltip>
            ) : (
              <TextStyled>
                {isNonDDG
                  ? getColumnValueNonDDG(column, seedDemand)
                  : getColumnValue(column, seedDemand)}
              </TextStyled>
            )}
          </TdMainRow>
        );
      })}
      <TdMainRow hasPaddingRight={true}>
        <IconChevronRightStyled
          isOpened={data.expanded}
          onClick={() => handleExpandableRowClick(data)}
        />
      </TdMainRow>
    </TrStyled>
  );
};

const DensityDemandTable = ({
  columns,
  dataSource,
  countryCode,
  isNonDDG,
  recommendationAssignments,
}: IProps) => {
  const [dataToDisplay, setDataToDisplay] = useState<SeedDensityField[]>([]);
  const [filterBy, setFilterBy] = useState<TSeedDemandKey | null>(null);
  const [sortOrder, setSortOrder] = useState<'DESC' | 'ASC'>('ASC');
  const [counter, setCounter] = useState(1);
  const [skuData, setSkuData] = useState<SkuDataItem[]>([]);
  const { t } = useTranslation();

  const {
    apiData: { recommendationCropwise, productCatalog },
  } = useAppState();
  const filteredColumnsBasedOnSku = isNonDDG ? columns : columns.filter((col) => col.key !== 'sku');

  const configRecommendation =
    recommendationCropwise?.recommendations[0].multi_field.config.recommendation;

  const getRequiredProduct = (product_name: any, field_id: any, isOverride = false) => {
    let override = null;
    const product = productCatalog.find(
      (item) => item.productName === product_name || item.commercialName === product_name
    );
    if (product) {
      const requiredData = recommendationAssignments.find((ass: any) => ass.field_id === field_id);
      let assignmentProduct =
        product && requiredData?.products.find((item: any) => item.product_id === product.id);
      if (isOverride && !assignmentProduct) {
        assignmentProduct = requiredData?.products[0];
        override = productCatalog.find((p) => p.id === requiredData.products[0].product_id);
      }
      return { ...assignmentProduct, override };
    }
  };

  const titleAsPerCountry = (item: { title: string; key: string; dataIndex: string }) => {
    if (item.key === 'seeds') {
      switch (true) {
        case countryCode === CropConstants.BULGARIA_CODE:
          return t('Seeds/decare');
        case countryCode === CropConstants.SOUTH_AFRICA_CODE:
          return `${t('Seed Rate')} (${t('Kg/Ha')})`;
        default:
          return t(item.title);
      }
    }
    return t(item.title);
  };
  const updatedColumns = filteredColumnsBasedOnSku
    .filter((item) => {
      return (
        !(item.key === 'plants' && countryCode === CropConstants.SOUTH_AFRICA_CODE) &&
        !(item.key === 'bagSize' && countryCode !== CropConstants.SOUTH_AFRICA_CODE)
      );
    })
    .map((item) => {
      return {
        ...item,
        title: titleAsPerCountry(item),
      };
    });

  const getSKUForNonDDGSolutions = (productName: string) => {
    const recommendatedProduct = productCatalog?.find((item) => item.productName === productName);
    return recommendatedProduct?.sku[0]?.description;
  };

  useEffect(() => {
    const DataSource = dataSource.map((field, index) => ({
      ...field,
      expanded: index === 0,
    }));
    if (recommendationCropwise?.recommendations?.length) {
      const skuDataFetch = recommendationCropwise.recommendations[0].products.map((product) => ({
        commercial_name: product.commercial_name,
        sku_description:
          (product.sku?.description || getSKUForNonDDGSolutions(product.product_name)) ?? '',
      }));
      setSkuData(skuDataFetch);
      setDataToDisplay(DataSource);
    }
  }, [dataSource, recommendationCropwise]);

  useEffect(() => {
    if (!filterBy) {
      setDataToDisplay(dataSource);
      return;
    }
    let sortedData: SeedDensityField[] = [];
    const orderValue = sortOrder === 'ASC' ? -1 : 1;
    if (filterBy === 'fieldName') {
      if (counter === 3) {
        sortedData = [...dataSource];
        setCounter(0);
        setSortOrder('ASC');
        setDataToDisplay(sortedData);
        return;
      }
      sortedData = [...dataToDisplay].sort((fElement, sElement) => {
        if (fElement.name < sElement.name) {
          return orderValue;
        }
        if (fElement.name > sElement.name) {
          return orderValue * -1;
        }
        return 0;
      });
      setCounter((prevState) => prevState + 1);
    } else {
      sortedData = [...dataToDisplay].map((item) => {
        const copyItem: SeedDensityField = { ...item };
        copyItem.seedDemand = item.seedDemand.slice(0, 3);
        copyItem.seedDemand.sort((firstElement, secondElement) => {
          // @ts-ignore
          if (firstElement[filterBy] < secondElement[filterBy]) {
            return orderValue;
          }
          // @ts-ignore
          if (firstElement[filterBy] > secondElement[filterBy]) {
            return orderValue * -1;
          }
          return 0;
        });
        return copyItem;
      });
      setCounter(0);
    }
    setDataToDisplay(sortedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy, sortOrder]);

  const handleExpandableRowClick = (rowData: SeedDensityField) => {
    const dataToDisplayCopy = [...dataToDisplay].map((row) => {
      if (row.name === rowData.name) {
        return {
          ...row,
          expanded: !row.expanded,
        };
      }
      return row;
    });
    setDataToDisplay(dataToDisplayCopy);
    track('recommendation interaction', { 'seed advice interaction': true });
  };

  const handleOnColumnTitleToSortClick = (key: TSeedDemandKey) => {
    if (key === 'fieldName' && counter === 3) {
      setSortOrder('ASC');
    }
    setFilterBy(key);
    const sortOrderOppositeMap = {
      ASC: 'DESC',
      DESC: 'ASC',
    };
    const sortOrderOpposite =
      key === filterBy ? (sortOrderOppositeMap[sortOrder] as 'ASC' | 'DESC') : sortOrder;
    track('recommendation interaction', { 'seed advice interaction': true });
    setSortOrder(sortOrderOpposite);
  };
  const getSkuDescription = (varietyName: string) => {
    const matchedSku = skuData.find(
      (sku) => sku.commercial_name.toLowerCase() === varietyName.toLowerCase()
    );
    return matchedSku ? matchedSku.sku_description : 'N/A';
  };

  const renderExpandableRow = (field: SeedDensityField) => {
    const seedsDemand = [...field.seedDemand].filter((seedDemand) => {
      const rank =
        typeof seedDemand.rank === 'string' ? parseInt(seedDemand.rank) : seedDemand.rank;
      return rank < 4;
    }); // We only need the first 3 ranks
    seedsDemand.shift();

    return (
      <>
        <MainRow
          data={field}
          updatedColumns={updatedColumns}
          handleExpandableRowClick={handleExpandableRowClick}
          getSkuDescription={getSkuDescription}
          isNonDDG={isNonDDG}
          nonDDGDataOverride={getRequiredProduct(field.SelectedSeed.SeedName, field.FieldId, true)}
        />
        {field.expanded &&
          seedsDemand.map((seedDemandItem, index) => (
            <ExpandableRow
              key={index}
              field={field.id}
              data={seedDemandItem}
              index={index}
              updatedColumns={updatedColumns}
              productCatalog={productCatalog}
              getSkuDescription={getSkuDescription}
              isNonDDG={isNonDDG}
              nonDDGDataOverride={getRequiredProduct(seedDemandItem?.Seed?.SeedName, field.FieldId)}
              configRecommendation={configRecommendation}
            />
          ))}
      </>
    );
  };

  const renderTitleColumn = (columnInformation: TColumn) => (
    <TdColumnTitle
      skuColumn={columnInformation.key === 'sku'}
      centerText={columnInformation.key === 'plantingArea'}
    >
      {columnInformation.key === 'variety' ? (
        <ColumnTitle>{columnInformation.title}</ColumnTitle>
      ) : (
        <DivFilterStyled
          onClick={() => handleOnColumnTitleToSortClick(columnInformation.key as TSeedDemandKey)}
        >
          <ColumnTitle>{columnInformation.title}</ColumnTitle>
          <IconSortStyled
            onClick={() => handleOnColumnTitleToSortClick(columnInformation.key as TSeedDemandKey)}
          />
        </DivFilterStyled>
      )}
    </TdColumnTitle>
  );

  return (
    <TableStyled>
      <thead>
        <TrTitleRow>
          <TdColumnTitle
            hasMarginLeft={true}
            onClick={() => handleOnColumnTitleToSortClick(updatedColumns[0].key as TSeedDemandKey)}
          >
            <DivFilterStyled
              onClick={() =>
                handleOnColumnTitleToSortClick(updatedColumns[0].key as TSeedDemandKey)
              }
            >
              <ColumnTitle>{updatedColumns[0].title}</ColumnTitle>
              <IconSortStyled
                onClick={() =>
                  handleOnColumnTitleToSortClick(updatedColumns[0].key as TSeedDemandKey)
                }
              />
            </DivFilterStyled>
          </TdColumnTitle>
          {updatedColumns.slice(1, updatedColumns.length).map(renderTitleColumn)}
          <td />
        </TrTitleRow>
      </thead>
      <tbody>
        {dataToDisplay.map((densityDemandInfo) => renderExpandableRow(densityDemandInfo))}
      </tbody>
    </TableStyled>
  );
};

DensityDemandTable.defaultProps = {
  columns: [
    {
      title: 'Field',
      key: 'fieldName',
      dataIndex: 'fieldName',
    },
    {
      title: 'Rank',
      key: 'rank',
      dataIndex: 'rank',
    },
    {
      title: 'Variety',
      key: 'variety',
      dataIndex: 'variety',
    },
    {
      title: 'Plants/m2',
      key: 'plants',
      dataIndex: 'plants',
    },
    {
      title: 'Seeds/ha',
      key: 'seeds',
      dataIndex: 'seeds',
    },
    {
      title: 'Area (ha)',
      key: 'plantingArea',
      dataIndex: 'plantingArea',
    },
    {
      title: 'Bag Size',
      key: 'bagSize',
      dataIndex: 'bagSize',
    },
    {
      title: 'Bags',
      key: 'numberOfBags',
      dataIndex: 'numberOfBags',
    },
    {
      title: 'SKU',
      key: 'sku',
      dataIndex: 'sku',
    },
  ],
};

export default DensityDemandTable;
