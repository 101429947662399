/* eslint-disable array-bracket-newline */
import * as turf from '@turf/turf';
import { getDeviceId } from '@amplitude/analytics-browser';
import lookup from 'country-code-lookup';
import track from 'utils/amplitudeWrapper';
import { Base64 } from 'js-base64';
import ButtonBase, { EButtonType } from 'components/Buttons/ButtonBase';
import { useAppState } from 'context/AppState';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useBreakpoint } from 'hooks';
import { Skeleton } from 'syngenta-digital-cropwise-react-ui-kit';
import { getBagsCount } from 'utils/getBagsCount';
import { generateEcommerceUrl } from 'utils/generateEcommerceUrl';
import { CropConstants } from 'utils/constants/Crop';
import { isPoland, isSouthAfrica, isUkraine } from 'utils/countryCode';
import {
  StickyBar,
  StyledSpinner,
  SkeletonStyled,
  SkeletonMobileContainer,
  SkeletonMobileTopContainer,
} from './ActionBar.styles';
import { SeedDensityField } from 'pages/RecommendationV2/Sections/SeedDensityAdvice';
import Download from 'assets/icons/icon-download.svg';
import Voucher from 'assets/icons/icon-voucher.svg';
import BuyNow from 'assets/icons/buy-now.svg';
import { getEmailId } from 'utils/contactEmail';
import { SeedRateProp, getProductSeedRate } from 'utils/helpers/filterProducts';

interface ActionBarProps {
  isLoading?: boolean;
  cropId?: string | number;
  pageLoadStartTime: number;
  showDownloadBtn?: boolean;
  allProductSeedRate?: SeedRateProp[];
}
interface contactAttribute {
  firstName: string;
  lastName: string;
}

interface ButtonsComponentProps {
  country: string;
  downloadPdf: () => void | Promise<void>;
  t: TFunction;
  voucherParams?: string;
  showDownloadBtn?: boolean;
  isDDG?: boolean;
}

const ActionBarButtons = ({
  country,
  downloadPdf,
  t,
  voucherParams,
  showDownloadBtn = true,
  isDDG,
}: ButtonsComponentProps) => {
  const { isMobile } = useBreakpoint();
  const deviceId = getDeviceId();

  const tempHideDownloadPdf = false;

  const handleBuyClick = () => track('process', { 'commercial process': 'e-commerce' });
  const handleEVoucherClick = () => track('process', { 'commercial process': 'e-voucher' });
  const primaryBtnWidth = () => {
    if (isMobile) {
      if (!tempHideDownloadPdf) {
        return '100%';
      }
      if (showDownloadBtn) {
        if (!isDDG) {
          return '';
        }
        return '48%';
      }
      return '100%';
    }
    return '';
  };

  const DownloadBtnWidth = () => {
    if (isMobile) {
      if (!isDDG) {
        return '100%';
      }
      return '48%';
    }
    return '';
  };

  const showPreBookSeedsBtn = () => {
    return isDDG;
  };

  if (isPoland(country)) {
    const url = generateEcommerceUrl();
    return (
      <>
        {showDownloadBtn && tempHideDownloadPdf && (
          <ButtonBase
            text={t('Download PDF')}
            textSize="MEDIUM"
            className="headerBtnWrap"
            buttonTestId="testid-download-pdf-btn"
            onClick={downloadPdf}
            style={{
              height: '44px',
              borderRadius: '4px',
              fontWeight: '400',
              border: '1px solid var(--Neutral-neutral-30, #C2C7D0)',
              background: 'var(--Neutral-neutral-00, #FFF)',
              width: DownloadBtnWidth(),
            }}
            iconAlign="LEFT"
            icon={
              <img src={Download} alt="No img found" style={{ width: '20px', height: '20px' }} />
            }
            isDisabled={true}
          />
        )}
        <a
          href={`${url}/${voucherParams}?deviceId=${deviceId}`}
          target="_blank"
          rel="noreferrer"
          style={{
            width: primaryBtnWidth(),
          }}
        >
          <ButtonBase
            className="headerBtnWrap"
            text={t('Buy Now')}
            textSize="MEDIUM"
            type={EButtonType.primary}
            fullWidth={isMobile}
            onClick={handleBuyClick}
            style={{ height: '44px' }}
            iconAlign="LEFT"
            icon={<img src={BuyNow} alt="No img found" style={{ width: '20px', height: '20px' }} />}
          />
        </a>
      </>
    );
  } else {
    return (
      <>
        {showDownloadBtn && tempHideDownloadPdf && (
          <ButtonBase
            text={t('Download PDF')}
            className="headerBtnWrap"
            textSize="MEDIUM"
            buttonTestId="testid-download-pdf-btn"
            onClick={downloadPdf}
            style={{
              height: '44px',
              borderRadius: '4px',
              fontWeight: '400',
              border: '1px solid var(--Neutral-neutral-30, #C2C7D0)',
              background: 'var(--Neutral-neutral-00, #FFF)',
              width: DownloadBtnWidth(),
            }}
            iconAlign="LEFT"
            icon={
              <img src={Download} alt="No img found" style={{ width: '20px', height: '20px' }} />
            }
            isDisabled={true}
          />
        )}
        {showPreBookSeedsBtn() && !isUkraine(country) && (
          <a
            href={
              isSouthAfrica(country)
                ? getEmailId(CropConstants.SOUTH_AFRICA_CODE)
                : `${process.env.REACT_APP_VOUCHER_URL}?param=${voucherParams}&deviceId=${deviceId}`
            }
            target="_blank"
            rel="noreferrer"
            style={{
              width: primaryBtnWidth(),
            }}
          >
            <ButtonBase
              textSize="MEDIUM"
              className="headerBtnWrap"
              text={isSouthAfrica(country) ? t('Contact Seed Sales') : t('Pre-book Seeds')}
              type={EButtonType.primary}
              fullWidth={isMobile}
              onClick={handleEVoucherClick}
              style={{ height: '44px' }}
              iconAlign="LEFT"
              icon={
                !isSouthAfrica(country) && (
                  <img src={Voucher} alt="No img found" style={{ width: '20px', height: '20px' }} />
                )
              }
            />
          </a>
        )}
      </>
    );
  }
};

export default function ActionBar(props: ActionBarProps) {
  const { isLoading, cropId, pageLoadStartTime, showDownloadBtn = true, allProductSeedRate } = props;
  const [btnDisplayDelay, setBtnDisplayDelay] = useState(true);
  const [gettingPDF, setGettingPDF] = useState(false);
  const { setVoucherParams } = useRecommendationFormActions();

  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const {
    apiData: { recommendation, recommendationCropwise, productCatalog, countryList },
    recommendationForm: { voucherParams, recommendationSeedsInfo },
  } = useAppState();
  const [country] = useState(recommendationCropwise?.country);
  const { userContactFromAudit } = recommendation || {};
  const { POLAND, SOUTH_AFRICA_CODE, CORN_SMALLCASE, GRAIN_CORN } = CropConstants;
  const isDDG = recommendationCropwise?.is_ddg;

  // const getFileName = (contact: contactAttribute) => {
  //   let name = 'Syngenta-Recommendation';
  //   const countryListWithGrowerName = ['ro', 'hu'];
  //   const countryCode = recommendationCropwise?.country
  //     ? lookup.byCountry(recommendationCropwise?.country?.trim())?.iso2.toLocaleLowerCase()
  //     : '';
  //   if (countryCode) {
  //     if (countryListWithGrowerName.includes(countryCode)) {
  //       const growerName = `${contact?.firstName || ''} ${contact?.lastName || ''}`;
  //       name = `
  //       ${
  //         pdfNamePerCountry.find((con) => con.countryCode === countryCode)?.text ?? ''
  //       }${growerName}`;
  //     } else {
  //       name = pdfNamePerCountry.find((con) => con.countryCode === countryCode)?.text ?? '';
  //     }
  //   }
  //   return name;
  // };

  // const getPreparedFor = (contact: any) => ` ${contact.firstName} ${contact.lastName}`;
  // const PDFPayloadGenerator = usePDFPayloadGenerator({
  //   fileName: getFileName(userContactFromAudit?.firstName ? userContactFromAudit : userContact),
  //   preparedFor: getPreparedFor(
  //     userContactFromAudit?.firstName ? userContactFromAudit : userContact
  //   ),
  //   isLoading,
  // });

  // TODO: Need to handle downloadPdf functionality when Recommendation Page is ready.
  const downloadPdf = async () => {
    //   setGettingPDF(true);
    //   track('download PDF', { 'PDF downloaded': true });
    //   const downloadPDFClickedAt = Date.now();
    //   const downloadPDFClickedTimeDuration = (downloadPDFClickedAt - pageLoadStartTime) / 1000;
    //   track('time taken to download pdf', {
    //     'time taken by user to download pdf': downloadPDFClickedTimeDuration,
    //   });
    //   try {
    //     const payload = await PDFPayloadGenerator();
    //     const pdfData = await ProxyLayer.renderPDF(payload);
    //     setGettingPDF(false);
    //     const url = window.URL.createObjectURL(pdfData);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('target', '_blank');
    //     link.setAttribute('download', `${payload.title}.pdf`);
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   } catch (e) {
    //     console.error('Error!!', e);
    //     setGettingPDF(false);
    //   }
  };

  const getCountryCode = (prevCountryCode: string) => {
    const countryCode: string | null = prevCountryCode.trim();
    return lookup.byCountry(countryCode)?.iso2;
  };

  const getCapitalName = (name: string | undefined) => {
    return name ? name?.charAt(0).toUpperCase() + name?.slice(1) : '';
  };

  const getSeedRecommendationV2 = (seedDensity: SeedDensityField[], nextBestHybrids: string[]) => {
    const recommendationV2: { name: string; quantity: number }[] = [];
    if (seedDensity) {
      seedDensity.forEach((property: SeedDensityField) => {
        const topSeedDemandList = property.seedDemand;
        topSeedDemandList.slice(0, 1).forEach((demand: any) => {
          const demandIndex = recommendationV2.findIndex(
            (r) => r.name === (demand.Name ?? demand.SeedName)
          );
          if (demandIndex === -1) {
            recommendationV2.push({ name: demand.Name ?? demand.SeedName, quantity: demand.numberOfBags ?? demand.bags });
          } else {
            recommendationV2[demandIndex].quantity =
              recommendationV2[demandIndex].quantity + (demand.numberOfBags ?? demand.bags);
          }
        });
      });
    }
    if (nextBestHybrids) {
      nextBestHybrids.forEach((nextHybrid) => {
        if (
          recommendationV2.findIndex((r) => r.name === nextHybrid) === -1 &&
          recommendationV2.length < 3
        ) {
          recommendationV2.push({ name: nextHybrid, quantity: 0 });
        }
      });
    }
    return recommendationV2;
  };

  useEffect(() => {
    if (!countryList.length) {
      return;
    }
    // Generate voucher params
    if (recommendationCropwise && productCatalog.length) {
      const [
        {
          multi_field: {
            config: {
              recommendation: recommendationResults,
              agronomicInputs: recommendationAgronomicInputs,
            },
            geometry,
          },
        },
      ] = recommendationCropwise.recommendations;
      const { id: recommendationID } = recommendationCropwise;
      const [properties] = geometry.properties || [];
      const plots = properties?.fields.map((field) => {
        const [longitude, latitude] = turf.centroid(field.geometry).geometry.coordinates;
        return {
          name: field.name,
          latitude,
          longitude,
          acreage: Number(field.declared_area.toFixed(2)),
          acreageUnitOfMeasure: 'ha',
        };
      });
      const recommendedHybrids: { name: string; quantity: number }[] = recommendationResults
        .map((item) => {
          const field = properties?.fields.find((fieldItem) => fieldItem.id === item.FieldId);
          if (item?.SeedRankings?.length) {
            const seedName = item.SeedRankings[0].Seed?.SeedName;
            const countryCode = lookup.byCountry(recommendationCropwise.country)?.iso3;
            const product = productCatalog.find((p) => p.productName === seedName);
            const productSeedRate = getProductSeedRate(item.FieldId, product?.productName, allProductSeedRate) ?? Number(product?.defaultSeedingRate);
            const defaultSeedingRate = productSeedRate ?? 0;
            const hectares = field?.declared_area || 0;
            const spatialClass = recommendationAgronomicInputs?.spatialClass ?? '';
            const bags = getBagsCount({
              product,
              hectares,
              defaultSeedingRate,
              countryList,
              countryCode,
              cropId,
              spatialClass,
            });

            return {
              name: seedName,
              quantity: bags,
            };
          } else {
            return {
              name: 'N/A',
              quantity: 0,
            };
          }
        })
        .reduce((acc: any, current) => {
          // Sum same products/seeds
          const index = acc.findIndex((i: any) => i.name === current.name);
          if (index < 0) {
            acc.push(current);
          } else {
            acc[index].quantity += current.quantity;
          }

          return acc;
        }, []);

      const recoHybridsNames = recommendedHybrids.map((item) => item.name);

      const getSeedName = (rs: any) => {
        if (rs.SeedName) {
          return rs.SeedName;
        }
        return rs.Seed ? rs.Seed.SeedName : '';
      };

      let nextBestHybrids = recommendationResults
        .map((item) => item.SeedRankings.map((rs, index) => (index < 6 && (getSeedName(rs) || ''))))
        .flat()
        .filter((seed) => seed)
        .filter((seed) => !recoHybridsNames.includes(seed))
        .filter((value, index, self) => self.indexOf(value) === index);

      const updatedPhone = userContactFromAudit
        ? userContactFromAudit?.phoneNumber
        : recommendation?.phoneNumber;
      const phoneNumberBasedOnCountry =
        recommendationCropwise.country.toLowerCase().replace(/\s+/g, '') === POLAND.toLowerCase()
          ? updatedPhone?.slice(2)
          : updatedPhone;
      const countryCode = getCountryCode(recommendationCropwise.country);
      const firstName = getCapitalName(
        userContactFromAudit ? userContactFromAudit?.firstName : recommendation?.firstName
      );
      const lastName = getCapitalName(
        userContactFromAudit ? userContactFromAudit?.lastName : recommendation?.lastName
      );

      let recommendedHybridsV2: { name: string; quantity: number }[] = [];
      if (recommendationSeedsInfo) {
        recommendedHybridsV2 = getSeedRecommendationV2(recommendationSeedsInfo, nextBestHybrids);

        nextBestHybrids = nextBestHybrids
          .filter((nextHybrid) => !recommendedHybridsV2.find((h) => h.name === nextHybrid))
          .slice(0, 3);
      }

      const data = {
        recommendationID,
        Country: countryCode,
        cropName: recommendationCropwise.recommendations[0].crop_name === CORN_SMALLCASE ?
          GRAIN_CORN : recommendationCropwise.recommendations[0].crop_name,
        User: {
          Email: recommendation?.email,
          FirstName: firstName,
          LastName: lastName,
          phone: phoneNumberBasedOnCountry,
        },
        plots,
        recommendedHybrids: recommendedHybridsV2,
        nextBestHybrids,
      };
      const safeDataPayload = Base64.encode(JSON.stringify(data));
      setVoucherParams({ voucherParams: safeDataPayload });
    }
  }, [
    recommendation,
    recommendationCropwise,
    productCatalog,
    setVoucherParams,
    recommendationSeedsInfo,
    countryList,
    cropId,
    allProductSeedRate,
  ]);

  const SkeletonLoader = useMemo(() => {
    if (isMobile) {
      return (
        <SkeletonMobileContainer>
          <SkeletonMobileTopContainer>
            <SkeletonStyled active size="large" />
            {showDownloadBtn && <SkeletonStyled active size="large" />}
          </SkeletonMobileTopContainer>
        </SkeletonMobileContainer>
      );
    }
    return (
      <>
        <Skeleton.Button active size="large" />
        {showDownloadBtn && <Skeleton.Button active size="large" />}
      </>
    );
  }, [isMobile]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isLoading) {
        setBtnDisplayDelay(false);
      }
    }, 3500);
    return () => clearTimeout(timer);
  }, [isLoading]);

  let displayComponent = <></>;

  displayComponent = (
    <StickyBar isLoading={isLoading} showDownloadBtn={showDownloadBtn} isDDG={isDDG}>
      {!isUkraine(country) && (isLoading || btnDisplayDelay) ? (
        SkeletonLoader
      ) : (
        <ActionBarButtons
          t={t}
          downloadPdf={downloadPdf}
          country={country || recommendationCropwise?.country || ''}
          voucherParams={voucherParams}
          showDownloadBtn={showDownloadBtn}
          isDDG={isDDG}
        />
      )}
      {gettingPDF && <StyledSpinner />}
    </StickyBar>
  );

  return displayComponent;
}
