/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import track from 'utils/amplitudeWrapper';
import {
  TableStyled,
  TrStyled,
  RankText,
  VarietyText,
  TrExpandableRow,
  TableContainer,
  ExpandedTdStyled,
  NumberText,
  CommonText,
  DescriptionText,
  SkuContainer,
} from './DensityDemandTableMobile.styles';
import { useTranslation } from 'react-i18next';
import { IconChevronRightStyled, TdMainRow } from '../DensityDemandTable/styles';
import { SeedDensityField } from '../SeedDensityAdvice';
import { RankedSeed } from 'base/types/RecommendationCropwise';
import { numberToEuropeanFormat } from 'utils/numberFormat';
import FieldCeil from '../FieldCeilComponent';
import { useAppState } from 'context/AppState';
import { CropConstants } from 'utils/constants/Crop';
import { convertSeedsToBagsForProduct } from 'utils/convertSeedsToBagsForProduct';

type TSeedDemand = {
  rank: number | string;
  variety: string;
  seeds: string | number;
  plantingArea: string | number;
  numberOfBags: string | number;
};

type SkuDataItem = {
  commercial_name: string;
  sku_description: string;
};

interface IProps {
  dataSource: SeedDensityField[];
  isNonDDG: boolean;
  recommendationAssignments?: any;
  countryCode: string;
}

export type TData = {
  fieldName: string;
  fieldCoordinates: number[][][];
  seedDemand: TSeedDemand[];
  area: string | number;
  expanded?: boolean;
};

const MainRow = ({
  data,
  isNonDDG,
  nonDDGDataOverride,
  handleExpandableRowClick,
}: {
  data: SeedDensityField;
  isNonDDG?: boolean,
  nonDDGDataOverride?: any,
  handleExpandableRowClick: (rowData: SeedDensityField) => void;
}) => {
  const fieldData = {...data};

  if (isNonDDG && nonDDGDataOverride) {
    fieldData.calculated_area = nonDDGDataOverride.applied_area;
  }

  return (
    <TrStyled>
      <td>
        <FieldCeil {...fieldData} />
      </td>
      <TdMainRow hasPaddingRight={true}>
        <IconChevronRightStyled
          isOpened={data.expanded}
          onClick={() => handleExpandableRowClick(data)}
        />
      </TdMainRow>
    </TrStyled>
  );
};

const ExpandableRow = ({
  data,
  isNonDDG,
  nonDDGDataOverride,
  configRecommendation,
  field,
  skuData,
  countryCode,
}: {
  data: RankedSeed;
  isNonDDG: boolean;
  nonDDGDataOverride?: any;
  configRecommendation: any;
  skuData: SkuDataItem[];
  countryCode: string;
  field: string;
}) => {
  const [t] = useTranslation();
  const skuDetails = skuData.find(
    (item: { commercial_name: string }) => item.commercial_name === data.variety
  );
  const {
    apiData: { productCatalog },
  } = useAppState();

  const getExpandableRowValueForNonDDG = (key: string, data: RankedSeed) => {

    const seedName = data.Seed?.SeedName;

    const getSeedRate = () => {
      if (nonDDGDataOverride?.seed_rate) {
        return nonDDGDataOverride?.seed_rate;
      } else if (data && data.Seed) {
        const calculatedSeedRates = configRecommendation.find((item: any) => item.FieldId === field)?.CalculatedSeedRate.reduce((acc: any, item: any) => {
          return { ...acc, ...item };
        }, {});
        return calculatedSeedRates[data.Seed.SeedName];
      }
      return 0;
    };

    const plantingArea = nonDDGDataOverride?.applied_area ? nonDDGDataOverride?.applied_area : data[key as keyof RankedSeed] as number;
    if (key === 'sku') {
      return '';
    } else if (key === 'numberOfBags') {
      const bags = Math.ceil(nonDDGDataOverride?.total_quantity_required);
      if (!bags && data.Seed) {
        const product = productCatalog.find((item: any) => item.productName === seedName || item.commercialName === seedName);
        return Math.ceil(convertSeedsToBagsForProduct(getSeedRate(), plantingArea, product));
      }
      return bags;
    } else if (key === 'seeds') {
      return numberToEuropeanFormat(getSeedRate());
    } else if (key === 'plantingArea') {
      return plantingArea.toFixed(2);
    } else if (key === 'plants') {
      return data?.plants;
    } else if (typeof data[key as keyof RankedSeed] === 'number') {
      return numberToEuropeanFormat(data[key as keyof RankedSeed] as number) || '';
    } else {
      return (data[key as keyof RankedSeed] as number) || '';
    }
  };

  return (
    <TrExpandableRow>
      <ExpandedTdStyled>
        <RankText>{data.rank}</RankText>
        <VarietyText>{data.variety}</VarietyText>
      </ExpandedTdStyled>
      <ExpandedTdStyled>
        <CommonText>{t('Seeds/ha')}</CommonText>
        <NumberText>{isNonDDG ? getExpandableRowValueForNonDDG('seeds', data) : numberToEuropeanFormat(data.seeds as number)}</NumberText>
      </ExpandedTdStyled>
      <ExpandedTdStyled>
        <CommonText>{t('Area (ha)')}</CommonText>
        <NumberText>{isNonDDG ? getExpandableRowValueForNonDDG('plantingArea', data) : numberToEuropeanFormat(data.plantingArea as number)}</NumberText>
      </ExpandedTdStyled>
      <ExpandedTdStyled>
        <CommonText>{t('Bags')}</CommonText>
        <NumberText>{isNonDDG ? getExpandableRowValueForNonDDG('numberOfBags', data) : numberToEuropeanFormat(data.numberOfBags as number)}</NumberText>
      </ExpandedTdStyled>
      {countryCode === CropConstants.SOUTH_AFRICA_CODE && (
        <ExpandedTdStyled>
          <CommonText>{t('Bag size')}</CommonText>
          <NumberText>{numberToEuropeanFormat(data.bagSize as number)}</NumberText>
        </ExpandedTdStyled>
      )}
      {countryCode !== CropConstants.SOUTH_AFRICA_CODE && (
        <ExpandedTdStyled>
          <CommonText>{t('Plants/m2')}</CommonText>
          <NumberText>{(data.plants as number)}</NumberText>
        </ExpandedTdStyled>
      )}
      {isNonDDG && (
        <SkuContainer>
          <ExpandedTdStyled>
            <CommonText>{t('SKU')}</CommonText>
          </ExpandedTdStyled>
          <ExpandedTdStyled>
            <DescriptionText className="description">
              {skuDetails?.sku_description ?? 'N/A'}
            </DescriptionText>
          </ExpandedTdStyled>
        </SkuContainer>
      )}
    </TrExpandableRow>
  );
};

const DensityDemandTableMobile = ({ dataSource, isNonDDG, countryCode, recommendationAssignments }: IProps) => {
  const [dataToDisplay, setDataToDisplay] = useState<SeedDensityField[]>([]);
  const [skuData, setSkuData] = useState<SkuDataItem[]>([]);
  const {
    apiData: { recommendationCropwise, productCatalog },
  } = useAppState();

  const configRecommendation = recommendationCropwise?.recommendations[0].multi_field.config.recommendation;

  const getSKUForNonDDGSolutions = (productName: string) => {
    const recommendatedProduct = productCatalog?.find((item) => item.productName === productName);
    return recommendatedProduct?.sku[0]?.description;
  };

  const getRequiredProduct = (product_name: any, field_id: any) => {
    const product = productCatalog.find((item) => item.productName === product_name || item.commercialName === product_name);
    const requiredData = recommendationAssignments.find((ass: any) => ass.field_id === field_id);
    const assignmentProduct = product && requiredData.products.find((item: any) => item.product_id === product.id);
    return assignmentProduct;
  };

  useEffect(() => {
    const updatedDataSource = dataSource.map((field, index) => ({
      ...field,
      expanded: index === 0,
    }));
    setDataToDisplay(updatedDataSource);
  }, [dataSource]);

  useEffect(() => {
    if (recommendationCropwise?.recommendations?.length) {
      const skuDataFetch = recommendationCropwise.recommendations[0].products.map((product) => ({
        commercial_name: product.commercial_name,
        sku_description:
          (product.sku?.description || getSKUForNonDDGSolutions(product.product_name)) ?? '',
      }));
      setSkuData(skuDataFetch);
    }
  }, [recommendationCropwise]);

  const handleExpandableRowClick = (rowData: SeedDensityField) => {
    const dataToDisplayCopy = [...dataToDisplay].map((row) => {
      if (row.name === rowData.name) {
        return {
          ...row,
          expanded: !row.expanded,
        };
      }
      return row;
    });
    setDataToDisplay(dataToDisplayCopy);
    track('recommendation interaction', { 'seed advice interaction': true });
  };

  const renderExpandableRow = (field: SeedDensityField) => {
    const seedsDemand = [...field.seedDemand].filter((seedDemand) => {
      const rank =
        typeof seedDemand.rank === 'string' ? parseInt(seedDemand.rank) : seedDemand.rank;
      return rank < 4;
    }); // We only need the first 3 ranks
    return (
      <>
        <MainRow
          data={field}
          isNonDDG={isNonDDG}
          nonDDGDataOverride={getRequiredProduct(field.SelectedSeed.SeedName, field.FieldId)}
          handleExpandableRowClick={handleExpandableRowClick}
        />
        {field.expanded &&
          seedsDemand.map((seed) => (
            <ExpandableRow
              key={seed.rank}
              data={seed}
              isNonDDG={isNonDDG}
              skuData={skuData}
              countryCode={countryCode}
              field={field.FieldId}
              configRecommendation={configRecommendation}
              nonDDGDataOverride={getRequiredProduct(seed.SeedName, field.FieldId)}
            />
          ))}
      </>
    );
  };

  return (
    <TableContainer>
      <TableStyled>
        <tbody>
          {dataToDisplay.map((densityDemandInfo) => renderExpandableRow(densityDemandInfo))}
        </tbody>
      </TableStyled>
    </TableContainer>
  );
};

export default DensityDemandTableMobile;
