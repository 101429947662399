import {
  StyledPopup,
  FieldInfoSection,
  FieldDetailSection,
  Text,
  SubTextSection,
  HectareText,
  SeedInfoSection,
  SeedImageSection,
  ProductDiv,
  ProductSection,
  ProductText,
  StyledDivider,
  SoilInfoSection,
  SoilHeaderText,
  SoilDataSection,
  SoilColorDiv,
  SoilText,
} from './FieldPopup.styles';
import FieldPreview from 'components/FieldPreview';
import { useTranslation } from 'react-i18next';
import seeds from '../../../assets/icons/seeds.svg';
import { PopUpdata } from './SoilCharacteristics';
import { getTranslatedFieldName } from 'utils/constants/Fields';

interface MapboxPopupProps {
  data: PopUpdata;
}

const Fieldpopup = ({ data }: MapboxPopupProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledPopup data-testid="field-pop-up-mobile">
      <FieldInfoSection>
        <FieldPreview size={28} coords={data.featureGeometry} viewboxSize={58} />
        <FieldDetailSection>
          <Text>{getTranslatedFieldName({ defaultName: data.fieldName ?? '', t })}</Text>
          <SubTextSection>
            <HectareText>{`${data.calculated_area} ${t('ha')}`}</HectareText>
          </SubTextSection>
        </FieldDetailSection>
      </FieldInfoSection>
      <SeedInfoSection>
        <SeedImageSection>
          <img src={seeds} alt="icon" />
        </SeedImageSection>
        <ProductDiv>
          <ProductSection color={data.productcolor}>
            <ProductText color={data.productcolor}>{data.productcommercialName}</ProductText>
          </ProductSection>
        </ProductDiv>
      </SeedInfoSection>
      <StyledDivider />
      <SoilInfoSection>
        <SoilHeaderText>{t('Soil Info')}</SoilHeaderText>
        <SoilDataSection>
          <SoilColorDiv color={data.soilColor} />
          <SoilText>{data.soilType}</SoilText>
        </SoilDataSection>
      </SoilInfoSection>
    </StyledPopup>
  );
};

export default Fieldpopup;
