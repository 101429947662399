import {
  FieldInfoSection,
  FieldDetailSection,
  FieldImageSection,
  Text,
  DividerText,
  HectareText,
} from './ProductFieldInfoSection.styles';
import FieldPreview from 'components/FieldPreview';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'context/AppState';
import { getTranslatedFieldName } from 'utils/constants/Fields';

interface Field {
  isMobile: boolean;
}
const ProductFieldsInfoSection = ({ isMobile }: Field): JSX.Element => {
  const { t } = useTranslation();
  const {
    flow: { selectedProductFieldInfo },
  } = useAppState();

  return (
    <>
      {selectedProductFieldInfo?.map((item) => (
        <FieldInfoSection key={item.fieldName}>
          <FieldImageSection>
            <FieldPreview
              fillColor={item.color}
              size={isMobile ? 18 : 22}
              coords={item.coords}
              viewboxSize={isMobile ? 48 : 58}
            />
          </FieldImageSection>
          <FieldDetailSection>
            <Text>{getTranslatedFieldName({ defaultName: item.fieldName, t })}</Text>
            <DividerText>|</DividerText>
            <HectareText>{`${Number(item.size).toFixed(2)} ${t('ha')}`}</HectareText>
          </FieldDetailSection>
        </FieldInfoSection>
      ))}
    </>
  );
};

export default ProductFieldsInfoSection;
