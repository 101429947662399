/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-empty-function */

const polarToX = (angle, distance) => Math.cos(angle - Math.PI / 2) * distance;

const polarToY = (angle, distance) => Math.sin(angle - Math.PI / 2) * distance;

const singleTextRepresentation = [
  {
    text: 'Önmegtermékenyülés',
    breakPoint: 8,
  },
  {
    text: 'Növénymagasság',
    breakPoint: 6,
  },
  {
    text: 'Blütezeitpunkt',
    breakPoint: 5,
  },
  {
    text: 'Jugendentwicklung',
    breakPoint: 6,
  },
  {
    text: 'Standfestigkeit',
    breakPoint: 5,
  },
  {
    text: 'Pflanzenlänge',
    breakPoint: 8,
  },
  {
    text: 'Restpflanzenverdaulichkeit',
    breakPoint: 12,
  },
  {
    text: 'Stängelgesundheit',
    breakPoint: 7,
  },
  {
    text: 'Wurzelvitalität',
    breakPoint: 6,
  },
];

const multiTextRepresentation = [
  {
    text: 'Kukorica fülfolt (Sphacelotheca reiliana)',
    textArray: ['Kukorica fülfolt', '(Sphacelotheca reiliana)'],
  },
  { text: 'Root rot (Fusarium moniliforme)',
    textArray: ['Root rot', '(Fusarium moniliforme)'],
  },
  {
    text: 'Толерантність до кореневих і стеблових гнилей',
    textArray: ['Толерантність до', 'кореневих і', 'стеблових гнилей'],
  },
];

const points = (points) => {
  return points.map((point) => `${point[0].toFixed(4)},${point[1].toFixed(4)}`).join(' ');
};

const axis = (options) => (col, i) => {
  const { angle } = col;
  return (
    <polyline
      key={`poly-axis-${i}`}
      points={points([
        [0, 0],
        [polarToX(angle, options.chartSize / 2), polarToY(angle, options.chartSize / 2)],
      ])}
      {...options.axisProps(col)}
    />
  );
};

const dot = (columns, options) => (chartData, i) => {
  const data = chartData.data;
  const meta = chartData.meta || {};
  const extraProps = options.dotProps(meta);
  let extraPropsSvg = {};
  let mouseEnter = () => {
    // empty
  };
  let mouseLeave = () => {
    // empty
  };
  if (extraProps.mouseEnter) {
    mouseEnter = extraProps.mouseEnter;
  }
  if (extraProps.mouseLeave) {
    mouseLeave = extraProps.mouseLeave;
  }
  if (extraProps.r) {
    extraPropsSvg.r = extraProps.r;
  }
  if (extraProps.fill) {
    extraPropsSvg.fill = extraProps.fill;
  }
  if (extraProps.stoke) {
    extraPropsSvg.stroke = extraProps.stroke;
  }
  if (extraProps.stokeWidth) {
    extraPropsSvg.strokeWidth = extraProps.strokeWidth;
  }
  if (extraProps.fill) {
    extraPropsSvg.fill = extraProps.fill;
  }
  return columns.map((col) => {
    const val = data[col.key];

    if (typeof val !== 'number') {
      throw new Error(`Data set ${i} is invalid.`);
    }

    return (
      <circle
        key={`dot-${col.key}-${val}`}
        cx={polarToX(col.angle, (val * options.chartSize) / 2)}
        cy={polarToY(col.angle, (val * options.chartSize) / 2)}
        className={[extraProps.className, meta.class].join(' ')}
        onMouseEnter={() => mouseEnter({ key: col.key, value: val, idx: i })}
        onMouseLeave={() => mouseLeave({})}
        {...extraPropsSvg}
      />
    );
  });
};

const shape = (columns, options) => (chartData, i) => {
  const { data, meta = {} } = chartData;
  const extraProps = options.shapeProps(meta);
  let extraPropsSvg = {};
  if (!meta.fill) {
    meta.fill = meta.color;
  }
  if (meta.strokeWidth) {
    extraPropsSvg.strokeWidth = meta.strokeWidth;
  }
  if (meta.strokeDasharray) {
    extraPropsSvg.strokeDasharray = meta.strokeDasharray;
  }
  if (meta.strokeLinecap) {
    extraPropsSvg.strokeLinecap = meta.strokeLinecap;
  }

  if (columns.length === 0) {
    return null;
  }

  return (
    <path
      key={`shape-${i}`}
      d={options.smoothing(
        columns?.map((col) => {
          const val = data?.[col.key];

          if (typeof val !== 'number') {
            return [0, 0];
          }

          return [
            polarToX(col.angle, (val * options.chartSize) / 2),
            polarToY(col.angle, (val * options.chartSize) / 2),
          ];
        })
      )}
      {...extraProps}
      {...extraPropsSvg}
      stroke={meta.color}
      fill={meta.fill}
      className={[extraProps.className, meta.class].join(' ')}
    />
  );
};

const scale = (options, value) => {
  return (
    <circle
      key={`circle-${value}`}
      cx={0}
      cy={0}
      r={(value * options.chartSize) / 2}
      {...options.scaleProps(value)}
    />
  );
};

const scaleLines = (value, columns, options) => {
  const chartRadio = (value * options.chartSize) / 2;

  return (
    <polyline
      key={`scale-line-${value}`}
      points={points([
        ...columns.map((col) => {
          return [polarToX(col.angle, chartRadio), polarToY(col.angle, chartRadio)];
        }),
        [polarToX(columns[0]?.angle, chartRadio), polarToY(columns[0]?.angle, chartRadio)],
      ])}
      {...options.scaleProps(value)}
    />
  );
};

const caption = (options) => (col) => {
  let wrapped = 1;
  if (col.caption.length > options.wrapCaptionAt) {
    wrapped = Math.round(col.caption.length / options.wrapCaptionAt);
  }
  const fontSize = (options.captionProps(col).fontSize || 12) / 2;
  const margin = options.captionProps(col).margin || 0;
  return new Array(wrapped).fill().map((_, index) => {
    const currentCaption = col.caption.substring(
      index * options.wrapCaptionAt,
      (index + 1) * options.wrapCaptionAt
    );
    let legends = [];
    if (currentCaption.length >= 13 && options.captionProps(col).textAnchor !== 'middle') {
      const firstWhiteSpace = currentCaption.indexOf(' ');
      if (firstWhiteSpace === -1) {
        const singleText = singleTextRepresentation.find((item) => item.text === currentCaption);
        if (singleText) {
          legends.push(currentCaption.substring(0, singleText.breakPoint) + '-');
          legends.push(currentCaption.substring(singleText.breakPoint, currentCaption.length));
        } else {
          legends.push(currentCaption.substring(0, currentCaption.length / 2) + '-');
          legends.push(currentCaption.substring(currentCaption.length / 2, currentCaption.length));
        }
      } else {
        const multiText = multiTextRepresentation.find((item) => item.text === currentCaption);
        if (multiText) {
          legends = [...multiText.textArray];
        } else {
          legends.push(currentCaption.substring(0, firstWhiteSpace));
          legends.push(currentCaption.substring(firstWhiteSpace, currentCaption.length));
        }
      }
    }

    return (
      <text
        key={`caption-of-${col.key}-${index}`}
        x={polarToX(col.angle, options.chartSize / 2 + margin).toFixed(4)}
        y={(
          polarToY(col.angle, options.chartSize / 2 + margin) +
          options.captionLineHeight * index
        ).toFixed(4)}
        dy={fontSize}
        {...options.captionProps(col)}
      >
        {legends.length > 0
          ? legends.map((legend, idx) => (
            <tspan
              key={idx}
              x={polarToX(col.angle, options.chartSize / 2 + margin).toFixed(4)}
              dy={fontSize + 10}
            >
              {legend}
            </tspan>
          ))
          : currentCaption}
      </text>
    );
  });
};

const render = (captions, chartData, options = {}) => {
  if (!captions) {
    return;
  }
  if (typeof captions !== 'object' || Array.isArray(captions)) {
    throw new Error('caption must be an object');
  }
  if (!Array.isArray(chartData)) {
    throw new Error('data must be an array');
  }
  options.chartSize = options.size / options.zoomDistance;

  const { rotation = 0 } = options;

  const columns = Object.keys(captions).map((key, i, all) => {
    const angle = (Math.PI * 2 * i) / all.length + rotation * (Math.PI / 180);
    return {
      key,
      caption: captions[key],
      angle: angle,
    };
  });
  const groups = [<g key={`g-groups}`}>{chartData.map(shape(columns, options))}</g>];
  if (options.captions) {
    groups.push(<g key={`poly-captions`}>{columns.map(caption(options))}</g>);
  }
  if (options.dots) {
    groups.push(<g key={`g-dots`}>{chartData.map(dot(columns, options))}</g>);
  }
  if (options.axes) {
    groups.unshift(<g key={`group-axes`}>{columns.map(axis(options))}</g>);
  }
  if (options.scales > 0) {
    const scales = [];
    for (let i = options.scales; i > 0; i--) {
      if (options.axesType === 'circle') {
        scales.push(scale(options, i / options.scales));
      } else {
        scales.push(scaleLines(i / options.scales, columns, options));
      }
    }
    groups.unshift(
      <g key={`poly-scales`} className="poly-scales">
        {scales}
      </g>
    );
  }
  const delta = (options.size / 2).toFixed(4);
  return <g transform={`translate(${delta},${delta})`}>{groups}</g>;
};

export { render as radar, caption, scaleLines, scale, points, axis, dot, shape };
